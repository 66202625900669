import React from "react";
import AddressAndTransport from "../components/AddressAndTransport";
import { Helmet } from "react-helmet";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import Layout from "../components/layout";
import { graphql, useStaticQuery } from "gatsby";
import {
	Button,
	Col,
	Container,
	Dropdown,
	Form,
	Row,
	Stack,
	DropdownButton,
} from "react-bootstrap";
import { parseNestedHTML } from "../Utils";
import HTMLReactParser from "html-react-parser";
import { GatsbyImage } from "gatsby-plugin-image";
import NinjaStarSVG from "../components/svg/NinjaStarSVG";
import { ValidationError } from "@formspree/react";
import SafeHtmlParser from "../components/safe-html-parser";
import ReactPlayer from "react-player";

const NinjaBirthdayParties = () => {
	const data = useStaticQuery(graphql`
		query {
			wpParty {
				partiesFields {
					address {
						btnText
						title
						location {
							lat
							lng
						}
					}
					heroSection {
						btnText
						title
					}
					birthdayParties {
						body
						subheading
						title
						btnText
					}
					childrensParties {
						body
						title
					}
					pricing {
						underTitleText
						price
						services {
							label
						}
						title
						image {
							node {
								altText
								localFile {
									publicURL
									childImageSharp {
										gatsbyImageData(
											formats: [AUTO, WEBP]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: CONTAIN }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
					}
					services {
						subheading
						title
						icon {
							node {
								sourceUrl
								altText
								localFile {
									publicURL
									childImageSharp {
										gatsbyImageData(
											formats: [AUTO, WEBP]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: CONTAIN }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
					}
					vehicleStatus {
						body
						title
						icon {
							node {
								sourceUrl
								altText
								localFile {
									publicURL
									childImageSharp {
										gatsbyImageData(
											formats: [AUTO, WEBP]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: CONTAIN }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
					}
				}
				seoFields {
					localBusinessSchema
					metaDescription
					opengraphDescription
					opengraphTitle
					title
					image {
						node {
							altText
							localFile {
								publicURL
								childImageSharp {
									original {
										height
										width
									}
									gatsbyImageData(
										formats: [AUTO, WEBP]
										quality: 100
										transformOptions: { cropFocus: CENTER, fit: CONTAIN }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
						}
					}
				}
			}
			site {
				siteMetadata {
					siteUrl
				}
			}
		}
	`);
	const siteUrl = data.site.siteMetadata.siteUrl;
	const NBPPage = data.wpParty.partiesFields;
	const seoFields = data.wpParty.seoFields;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "Ninja Birthday Parties",
				item: {
					url: `${siteUrl}/ninja-birthday-parties`,
					id: `${siteUrl}/ninja-birthday-parties`,
				},
			},
		],
	};
	return (
		<div>
			<Helmet>
				<script
					src="https://www.google.com/recaptcha/api.js"
					async
					defer
				></script>
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			{/* <LocalBusinessSchema /> */}
			<GatsbySeo
				title={seoFields?.title}
				description={seoFields?.metaDescription}
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/ninja-birthday-parties`,
					title: seoFields?.opengraphTitle,
					description: seoFields?.opengraphDescription,
					images: [
						{
							url: `${seoFields?.image?.node?.localFile.publicURL}`,
							width: `${seoFields?.image?.node?.localFile.childImageSharp.original.width}`,
							height: `${seoFields?.image?.node?.localFile.childImageSharp.original.height}`,
							alt: `${seoFields?.image?.node?.altText}`,
						},
					],
				}}
			/>
			<Layout>
				<section className="position-relative">
					<div className="position-relative ">
						<div
							style={{
								zIndex: 1,
								background:
									"linear-gradient(0deg, rgba(0, 0, 0, 0.40) 0%, rgba(0, 0, 0, 0.40) 100%)",
								borderTop: "1px solid #FFF",
								borderBottom: "1px solid #FFF",
							}}
							className="position-absolute h-100 w-100 "
						>
							<Container className="h-100">
								<Row className="  h-100 align-items-center">
									<Col className=" align-left text-left pb-lg-5 py-4 pt-lg-0 text-white">
										<div className="stylishText text-uppercase  ">
											<SafeHtmlParser htmlContent={NBPPage.heroSection.title} />
										</div>
										<Button
											href="#form"
											className="text-white text-uppercase w-100 w-md-auto fs-5 px-4 py-2"
										>
											{NBPPage.heroSection.btnText}
										</Button>
									</Col>
								</Row>
							</Container>
						</div>
						<div
							className="d-md-none"
							style={{ maxHeight: "80vh", overflow: "hidden" }}
						>
							<ReactPlayer
								loop
								playing
								muted
								playsinline
								className="  hero-vid"
								url="https://ninja-arena-eastbourne.rjmdigital.net/wp-content/uploads/2024/04/Birthday-Video-to-send-to-connor-Ninja-Arena-.mp4"
							/>
						</div>
						<div
							className="d-none d-md-block"
							style={{ maxHeight: "80vh", overflow: "hidden" }}
						>
							<ReactPlayer
								loop
								playing
								muted
								playsinline
								className="w-100 h-100"
								url="https://ninja-arena-eastbourne.rjmdigital.net/wp-content/uploads/2024/04/Birthday-Video-to-send-to-connor-Ninja-Arena-.mp4"
							/>
						</div>
					</div>
				</section>

				<section className="py-5 py-lg-7">
					<Container>
						<Row className="justify-content-center">
							<Col lg={8} className="text-center">
								<h2 className="display-5  text-uppercase">
									{NBPPage.birthdayParties.title}
								</h2>
								<p className="text-white">
									{NBPPage.birthdayParties.subheading}
								</p>
								<div>
									<SafeHtmlParser htmlContent={NBPPage.birthdayParties.body} />
								</div>
								<div className="d-flex justify-content-center">
									<Button
										href="#form"
										className="text-white text-uppercase w-100 w-md-auto fs-5 px-4 py-2"
									>
										{NBPPage.birthdayParties.btnText}
									</Button>
								</div>
							</Col>
						</Row>
					</Container>
				</section>
				<section>
					<Container>
						<Row>
							<Col>
								<div>
									<h2 className="display-5  text-uppercase">
										{NBPPage.childrensParties.title}
									</h2>
									<div>
										{" "}
										<SafeHtmlParser
											htmlContent={NBPPage.childrensParties.body}
										/>
									</div>
								</div>
							</Col>
						</Row>
					</Container>
				</section>
				<section className="mt-5 pb-4 pb-lg-7">
					<Container>
						<Row className="gy-4">
							{NBPPage.pricing.map(
								({ image, title, price, services, underTitleText }, i) => (
									<Col lg={4} key={i}>
										<div
											className="p-3 h-100"
											style={{ backgroundColor: "#181819", borderRadius: 8 }}
										>
											<GatsbyImage
												image={
													image?.node.localFile?.childImageSharp
														?.gatsbyImageData
												}
												style={{ objectFit: "contain", borderRadius: 12 }}
												alt={image?.node?.altText}
											/>
											<h3 className="fs-1 mt-3  text-uppercase">{title}</h3>
											{underTitleText && (
												<p className="pb-0 mb-0 text-white">{underTitleText}</p>
											)}
											<p className="text-white pt-3 pb-0 fs-5 karla-bold">
												{price}
											</p>
											<div className="d-md-none">
												{services.map(({ label }, i) => (
													<Stack
														direction="horizontal"
														className="align-items-start"
														gap={1}
														key={i}
													>
														<NinjaStarSVG
															style={{ minWidth: "15px", maxWidth: "15px" }}
														/>
														<p className="text-white pt-1">{label}</p>
													</Stack>
												))}
											</div>
											<div className="d-none d-md-block">
												{services.map(({ label }, i) => (
													<Stack
														direction="horizontal"
														className="align-items-start"
														gap={2}
														key={i}
													>
														<NinjaStarSVG
															style={{ minWidth: "20px", maxWidth: "20px" }}
														/>
														<p className="text-white pt-1">{label}</p>
													</Stack>
												))}
											</div>
										</div>
									</Col>
								)
							)}
						</Row>
					</Container>
				</section>
				<section className="pb-5 pb-lg-7">
					<Container>
						<Row>
							<Col>
								<Row className=" g-3">
									{NBPPage.services.map(({ icon, title, subheading }, i) => (
										<Col lg={3} md={6}>
											<Stack
												className="justify-content-start text-center h-100 align-items-center p-4"
												key={i}
												style={{
													backgroundColor: "#181819",
													borderRadius: 8,
												}}
											>
												<div
													className=" d-inline-block mb-4"
													style={{
														minWidth: "70px",
														width: "70px",
														maxHeight: "70px",
													}}
												>
													<img
														src={icon?.node?.sourceUrl}
														alt={icon?.node?.altText}
														className="w-100 mh-100"
													/>
												</div>

												<h3>{title}</h3>
												<p className="pb-0 mb-0">{subheading}</p>
											</Stack>
										</Col>
									))}
								</Row>
							</Col>
						</Row>
					</Container>
				</section>

				<section id="form" className="bg-black  ">
					<Container>
						<Row className="justify-content-center">
							<Col xl={7} lg={9}>
								<div
									style={{ borderRadius: "10px" }}
									className="w-100 bg-white px-md-5 px-0 py-5"
								>
									<h2 className="display-5 pb-0 text-center  text-uppercase pb-3 pb-md-4 text-black">
										Party enquries
									</h2>

									<iframe
										style={{ width: "100%", height: "900px" }}
										src="https://forms.roller.app/#/ninjaarenaeastbourne/9cf6e6abc29e451/form"
									></iframe>
								</div>
							</Col>
						</Row>
					</Container>
				</section>
				<section className="py-5 py-lg-7">
					<AddressAndTransport
						address={NBPPage.address}
						vehiclesStatus={NBPPage.vehicleStatus}
					/>
				</section>
			</Layout>
		</div>
	);
};

export default NinjaBirthdayParties;
